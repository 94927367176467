export const Work = [
  {
    id: 1,
    name: "16Clothing Store",
    tags: ["html", "css"],
    description: "First Landing Page that I made with HTML and CSS",
    live_demo: "https://16store.netlify.app/",
    git_source: "https://github.com/BakhromjonSadullaev/16clothing",
  },
  {
    id: 2,
    name: "Training Studio",
    tags: ["html", "css"],
    description:
      "I had many minor problems with making landing pages so my mentor adviced to practice a lot",
    live_demo: "https://trainings.netlify.app/",
    git_source: "https://github.com/Mirzoyo/Training-Studio",
  },
  {
    id: 3,
    name: "Upright Project",
    tags: ["html", "css", "javascript"],
    description:
      "This Website taught me a lot because it's landing page is quite difficult and I learned a lot",
    live_demo: "https://uprightby-mirzodev.netlify.app/",
    git_source: "https://github.com/Mirzoyo/Upright",
  },
  {
    id: 4,
    name: "Host Cloud",
    tags: ["html", "css", "javascript"],
    description:
      "In this project I have used my first javascript knowledge in practise",
    live_demo: "https://hostcloud-mirzodev.netlify.app/",
    git_source: "https://github.com/Mirzoyo/HostCloud",
  },
  {
    id: 5,
    name: "StarterRandom JS",
    tags: ["javascript"],
    description: "First Landing Page that I made with JavaScript",
    live_demo: "https://starterjs.netlify.app/",
    git_source: "https://github.com/Mirzoyo/starterjs",
  },
  {
    id: 6,
    name: "HexaShop Commerce",
    tags: ["html", "css", "javascript"],
    description:
      "I had many minor problems with making landing pages so my mentor adviced to practice a lot",
    live_demo: "https://ecommercehexashop.netlify.app/",
    git_source: "https://github.com/Mirzoyo/HexashopCommecerce",
  },
  {
    id: 7,
    name: "Tasbeeh Online",
    tags: ["javascript"],
    description:
      "Tasbeh is useful for Muslim people that's why I also made my Javascript Online Tasbeh",
    live_demo: "https://tasbeehh.netlify.app/",
    git_source: "https://github.com/Mirzoyo/TasbehOnline",
  },
  {
    id: 8,
    name: "Leadership Project",
    tags: ["html", "css", "javascript"],
    description:
      "This Website taught me a lot because it's landing page is quite difficult and I learned a lot",
    live_demo: "https://leadership-mirzodev.netlify.app/",
    git_source: "https://github.com/Mirzoyo/leadershipproject",
  },
  {
    id: 9,
    name: "10 Projects",
    tags: ["html", "css", "javascript"],
    description:
      "10 Landing HTML, CSS and Javascript projects in one GitHub file",
    live_demo:
      "https://images.unsplash.com/photo-1628155930542-3c7a64e2c833?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bm8lMjBpbWFnZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    git_source: "https://github.com/Mirzoyo/10projectswithFLORIN",
  },
  {
    id: 10,
    name: "JavaScript HoverBoard",
    tags: ["javascript"],
    description:
      "I made this project with help of YouTube and it looks like game",
    live_demo: "https://hoverboard-js.netlify.app/",
    git_source: "https://github.com/Mirzoyo/Hoverboard",
  },
  {
    id: 11,
    name: "Commerce Project",
    tags: ["html", "css", "javascript"],
    description: "This project grow my interest into javascript",
    live_demo: "https://commerce-javascript.netlify.app/",
    git_source: "https://github.com/Mirzoyo/CommerceMirzo",
  },
];
