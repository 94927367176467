import React, { useEffect, useRef } from "react";
import styled, { ThemeProvider } from "styled-components";
import { DarkTheme } from "./Themes";
import LogoComponent from "../subComponent/LogoComponent";
import SocialIcons from "../subComponent/SocialIcons";
import PowerButton from "../subComponent/PowerButton";
import { Work } from "../data/WorkData";
import Card from "../subComponent/Card";
import { YinYang } from "./AllSvgs";
import BigTitles from "../subComponent/BigTitles";
import { motion } from "framer-motion";
const Box = styled.div`
  background-color: ${(props) => props.theme.body};
  height: 400vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const Main = styled(motion.ul)`
  position: fixed;
  top: 12rem;
  left: calc(2rem+ 15vw);
  height: 40;
  display: flex;
  color: white;
  @media (max-width: 1010px) {
    left: 2rem;
  }
`;

const Rotate = styled.span`
  display: flex;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  width: 80px;
  height: 80px;
  z-index: 1;
`;
const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
      duration: 0.5,
    },
  },
};

const WorkPage = () => {
  const ref = useRef(null);
  const yinyang = useRef(null);

  useEffect(() => {
    let element = ref.current;
    const rotate = () => {
      element.style.transform = `translateX(${-window.pageYOffset}px)`;
      yinyang.current.style.transform =
        `rotate(` + -window.pageYOffset + `deg)`;
    };
    window.addEventListener("scroll", rotate);
    return () => window.removeEventListener("scroll", rotate);
  }, []);

  return (
    <ThemeProvider theme={DarkTheme}>
      <Box>
        <LogoComponent theme="dark" />
        <SocialIcons theme="dark" />
        <PowerButton />
        <Main ref={ref} variants={container} initial="hidden" animate="show">
          {Work.map((d) => (
            <Card key={d.id} data={d}>
              Work Data
            </Card>
          ))}
        </Main>
        <Rotate ref={yinyang}>
          <YinYang width={80} height={80} fill={DarkTheme.text} />
        </Rotate>
        <BigTitles text="WORK" top="10%" left="50%" />
      </Box>
    </ThemeProvider>
  );
};

export default WorkPage;
