export const Blogs = [
  {
    id: 1,
    name: "Tasbeeh WebApp in HTML & CSS & JavaScript",
    tags: ["html", "css", "styled-components"],
    date: "25 May, 2022",
    imgSrc:
      "https://images.unsplash.com/photo-1563300365-9c77e472e7a5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    link: "https://tasbeehh.netlify.app/",
  },
  {
    id: 2,
    name: "YouTube Best Tutorial that I have learned basic and most-used components in our projects is FlorinPop's Blog",
    tags: ["Javascript", "styled-components"],
    date: "5 June, 2022",
    imgSrc:
      "https://i.ytimg.com/vi/8GPPJpiLqHk/hqdefault.jpg?sqp=-oaymwEXCOADEI4CSFryq4qpAwkIARUAAIhCGAE=&rs=AOn4CLCTOtqJMoFiaMd0nGq86PEFOZRG3g",
    link: "https://youtu.be/8GPPJpiLqHk?list=PLgBH1CvjOA60Qx1x_2kDDhroGXBwvAFUK&t=16",
  },
  {
    id: 3,
    name: "When you have some problems in Javascript learning I highly recommend this blog on YouTube",
    tags: ["Javascript", "html/css", "react"],
    date: "15 June, 2022",
    imgSrc:
      "https://images.unsplash.com/photo-1461749280684-dccba630e2f6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80",
    link: "https://www.youtube.com/c/akshaymarch7",
  },
  {
    id: 4,
    name: "Why we should choose React library?",
    tags: ["react", "redux", "framer-motion"],
    date: "1 August, 2022",
    imgSrc:
      "https://images.unsplash.com/photo-1633356122102-3fe601e05bd2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
    link: "https://youtu.be/i8xsbYgMiBs",
  },
  {
    id: 5,
    name: "What is React Hooks",
    tags: ["react", "reactJS", "web-development"],
    date: "4 August, 2022",
    imgSrc:
      "https://images.unsplash.com/photo-1591267990532-e5bdb1b0ceb8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1127&q=80",
    link: "https://youtu.be/O6P86uwfdR0?list=PLZlA0Gpn_vH8EtggFGERCwMY5u5hOjf-h",
  },
  {
    id: 6,
    name: "How to implement Pagination Component in React from scratch",
    tags: ["react", "redux"],
    date: "9 August, 2022",
    imgSrc:
      "https://codebucks.hashnode.dev/_next/image?url=https%3A%2F%2Fcdn.hashnode.com%2Fres%2Fhashnode%2Fimage%2Fupload%2Fv1625240050668%2FGAya9b0XK.png%3Fw%3D1600%26h%3D840%26fit%3Dcrop%26crop%3Dentropy%26auto%3Dcompress%2Cformat%26format%3Dwebp&w=1920&q=75",
    link: "https://codebucks.hashnode.dev/pagination-in-react",
  },

  {
    id: 7,
    name: "What is Redux ? Simply Explained!",
    tags: ["react", "redux", "javascript"],
    date: "11 August, 2022",
    imgSrc:
      "https://res.cloudinary.com/practicaldev/image/fetch/s--ZY1EK-Eo--/c_imagga_scale,f_auto,fl_progressive,h_420,q_auto,w_1000/https://dev-to-uploads.s3.amazonaws.com/uploads/articles/bcz1u90oq3prr5fga3fs.png",
    link: "https://dev.to/codebucks/what-is-redux-simply-explained-2ch7",
  },
  {
    id: 8,
    name: "Additionally I also advise in order to make your knowledge strong you should look throw this blog even once",
    tags: ["html", "css", "javascript", "react"],
    date: "28 August, 2022",
    imgSrc: "https://pbs.twimg.com/media/FXfPyPvWYAI0xF9?format=png&name=large",
    link: "https://www.freecodecamp.org/learn",
  },
];
